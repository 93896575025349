<template>
  <div>

    <div class="mb-1 text-right">
      <b-button
        variant="primary"
        :to="{name:'admin-create-role'}"
      >
        Add Role
      </b-button>
    </div>

    <b-card class="p-1">
      <b-row>
        <b-col>
          <search
            placeholder="Name"
            :search-query.sync="searchQuery.name"
            @refresh="refreshTable"
          />
        </b-col>

        <b-col md="2">
          <search-button @refresh="refreshTable" />
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-table
        ref="table"
        :items="rolesProvider"
        :fields="tableColumns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        show-empty
        responsive
        empty-text="No roles found"
      >
        <template #cell(actions)="{item}">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'admin-edit-role', params: { roleId: item.id } }">
              <feather-icon icon="EditIcon" />
              <span> Edit Role </span>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'admin-edit-role-permissions', params: { roleId: item.id } }">
              <feather-icon icon="EditIcon" />
              <span> Edit Role Permissions </span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteRole(item.id)">
              <div class="text-danger">
                <feather-icon icon="XCircleIcon" />
                <span> Delete </span>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

    </b-card>

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />
  </div>
</template>
<script>
import Roles from '@/common/compositions/permissions/roles'
import paginationData from '@/common/compositions/common/paginationData'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import Search from '@/common/components/common/Table/Search.vue'

export default {
  name: 'PortalUsers',
  components: { Pagination, SearchButton, Search },
  data() {
    return {
      searchQuery: { name: null },
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'id' },
        { key: 'displayName' },
        { key: 'description' },
        { key: 'actions' },
      ]
    },
  },
  setup() {
    const { pagination } = paginationData()
    const { getEntityRoles, table, deleteRole } = Roles()
    return {
      getEntityRoles,
      table,
      deleteRole,
      pagination,
    }
  },
  methods: {
    rolesProvider() {
      return this.getEntityRoles('', { page: this.pagination.currentPage, ...this.searchQuery }).then(res => {
        this.pagination.totalRows = res.pagination.total
        return res.data
      }).catch(() => [])
    },
    refreshTable() {
      this.$refs.table.refresh()
    },
  },
}
</script>
<style lang="scss">

</style>
